export const aviw_Cost_WBSCostCurveData_AllLevels_Config = {
    id: "dsWBSCutOffsChart_" + crypto.randomUUID(),
    viewName: "aviw_Cost_WBSCostCurveData_AllLevels",
    maxRecords: -1,
    uniqueTable: '',
    allowInsert: false,
    allowDelete: false,
    allowUpdate: false,
    loadRecents: false,
    distinctRows: false,
    definitionProc: "astp_Cost_WBSCostCurveData_AllLevelsDefinition",
    selectFirstRowOnLoad: true, // do not remove
    fields: [
        { name: "WBS_ID", sortOrder: 1, sortDirection: "asc", type: null },
        { name: "CutOff", sortOrder: 2, sortDirection: "asc", type: null },
        { name: "WBS", type: null },
        { name: "OrgUnitIdPath", type: null },
        { name: "OrgUnit_ID", type: null },
        { name: "IsCurrentCutOff", type: null },
        { name: "ForecastAtCompletion", type: null },
        { name: "BudgetAtCompletion", type: null },
        { name: "AdjustedBudgetAtCompletion", type: null },
        { name: "PlannedValue", type: null },
        { name: "PlannedProgress", type: null },
        { name: "ActualProgress", type: null },
        { name: "ForecastProgress", type: null },
        { name: "ActualCost", type: null },
        { name: "EarnedValue", type: null },
        { name: "Booked", type: null },
        { name: "CommittedValue", type: null },
        { name: "PaymentForecast", type: null },
        { name: "ForecastValueCurve", type: null },
        { name: "Archive1Total", type: null },
        { name: "Archive2Total", type: null },
        { name: "Archive1Value", type: null },
        { name: "Archive2Value", type: null },
        { name: "DraftImportCurve", type: null },
        { name: "Archive2ValuePeriodic", type: null },
        { name: "Archive1Progress", type: null },
        { name: "Archive2Progress", type: null },
        { name: "PlannedValuePeriodic", type: null },
        { name: "ForecastValuePeriodicCurve", type: null },
        { name: "ActualCostPeriodic", type: null },
        { name: "EarnedValuePeriodic", type: null },
        { name: "BookedPeriodic", type: null },
        { name: "CommittedValuePeriodic", type: null },
        { name: "OriginalBaselineTotal", type: null },
        { name: "OriginalBaselineValue", type: null },
        { name: "OriginalBaselineValuePeriodic", type: null },
        { name: "PlannedHrs", type: null },
        { name: "ActualHrs", type: null },
        { name: "ForecastHrsCurve", type: null },
        { name: "EarnedHrs", type: null },
        { name: "OriginalBaselineHrs", type: null },
        { name: "Archive1Hrs", type: null },
        { name: "Archive2Hrs", type: null },
        { name: "PlannedHrsPeriodic", type: null },
        { name: "ActualHrsPeriodic", type: null },
        { name: "ForecastHrsPeriodicCurve", type: null },
        { name: "EarnedHrsPeriodic", type: null },
        { name: "OriginalBaselineHrsPeriodic", type: null },
        { name: "Archive1HrsPeriodic", type: null },
        { name: "Archive2HrsPeriodic", type: null },
        { name: "ForecastHrsTotal", type: null },
        { name: "PlannedHrsTotal", type: null },
        { name: "OriginalBaselineHrsTotal", type: null },
        { name: "DraftImportCurvePeriodic", type: null },
        { name: "Funding", type: null },
        { name: "StartDate", type: null },
        { name: "FinishDate", type: null },
    ]
};

export const aviw_Cost_ProjectBaselineArchives_Config = {
    id: 'dsProjectBaselineArchives_' + crypto.randomUUID(),
    viewName: 'aviw_Cost_ProjectBaselineArchives',
    maxRecords: 2,
    uniqueTable: '',
    allowInsert: false,
    allowDelete: false,
    allowUpdate: false,
    loadRecents: false,
    distinctRows: false,
    //dynamicLoading: false,
    fields: [
        { name: "ArchiveID", type: "number", sortOrder:"1", direction:"asc"},
        { name: "Baseline_ID", type: "number" },
        { name: "BaselineName", type: "string" },
        { name: "Project_ID", type: "number" },
        { name: "BaselineType", type: "string" },   
        //{name: "ArchiveName", type: "string" }
    ]
};

// export const aviw_Cost_WBSCostCurveData_Contingency_Config = {
//     id: "dsWBSCutOffsChart_" + crypto.randomUUID(),
//     viewName: "aviw_Cost_WBSCostCurveData_Contingency",
//     maxRecords: -1,
//     uniqueTable: '',
//     allowInsert: false,
//     allowDelete: false,
//     allowUpdate: false,
//     loadRecents: false,
//     distinctRows: false,
//     definitionProc: "astp_Cost_WBSCostCurveData_ContingencyDefinition",
//     selectFirstRowOnLoad: true, // do not remove
//     fields: [
//         { name: "OrgUnit_ID", type: null },
//         { name: "WBS_ID", sortOrder: 1, sortDirection: "asc", type: null },
//         { name: "CutOff", sortOrder: 2, sortDirection: "asc", type: null },
//         { name: "WBS", type: null },
//         { name: "ForecastAtCompletion", type: null },
//         { name: "BudgetAtCompletion", type: null },
//         { name: "PlannedValue", type: null },
//         { name: "ForecastValue", type: null },
//         { name: "ContingencyDrawdown", type: null },
//         { name: "IsCurrentCutOff", type: null }
//     ]
// }

export const aviw_Cost_WBSContingencyData_Config = {
    id: "dsWBSCutOffsChart_" + crypto.randomUUID(),
    viewName: "aviw_Cost_WBSContingencyData",
    maxRecords: -1,
    uniqueTable: '',
    allowInsert: false,
    allowDelete: false,
    allowUpdate: false,
    loadRecents: false,
    distinctRows: false,
    definitionProc: "astp_Cost_WBSContingencyDataDefinition",
    selectFirstRowOnLoad: true, // do not remove
    fields: [
        { name: "OrgUnit_ID", type: null },
        { name: "WBS_ID", sortOrder: 1, sortDirection: "asc", type: null },
        { name: "CutOff", sortOrder: 2, sortDirection: "asc", type: null },
        { name: "Planned", type: null },
        { name: "Forecast", type: null },
        { name: "ForecastOriginal", type: null },
        { name: "ForecastValue", type: null },
        { name: "ContingencyDrawdown", type: null },
        { name: "ForecastAtCompletion", type: null },
        { name: "Actual", type: null },
        { name: "IsCurrentCutOff", type: null }
    ]
}

export const aviw_Cost_WBSProgressData_Config = {
    id: "dsWBSCutOffsChart_" + crypto.randomUUID(),
    viewName: "aviw_Cost_WBSProgressCurve",
    maxRecords: -1,
    uniqueTable: '',
    allowInsert: false,
    allowDelete: false,
    allowUpdate: false,
    loadRecents: false,
    distinctRows: false,
    definitionProc: "astp_Cost_WBSProgressCurveDefinition",
    selectFirstRowOnLoad: true, // do not remove
    fields: [
        { name: "WBS_ID", type: null },
        { name: "WBS", sortOrder: 1, type: null },
        { name: "OrgUnit_ID", type: null },
        { name: "WBSidPath", type: null },
        { name: "CutOff", sortOrder: 1, sortDirection: "asc", type: null },
        { name: "IsCurrentCutOff", type: null },

        { name: "PlannedProgress", type: null },
        { name: "ActualProgress", type: null },
        { name: "ForecastProgress", type: null },

        { name: "PlannedProgressPeriodic", type: null },
        { name: "ActualProgressPeriodic", type: null },
        { name: "ForecastProgressPeriodic", type: null },
    ]
}